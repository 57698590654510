import React, { createContext, useContext, useMemo, useState } from "react";
import {
  type RatesDosageInterface,
  type NewTrialStateContextValue,
  type NewTrialStateInterface,
} from "../types";

const NewTrialStateContext = createContext<NewTrialStateContextValue | null>(
  null
);

export const NewTrialStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [trialInfo, setTrialInfo] = useState<any>({
    field_ids: [""],
  });
  const [newTrialState, setNewTrialState] = useState<NewTrialStateInterface>({
    ab_line: [],
    buffer_zone: [],
    machinery_grid: [],
    trial_plots: [],
  });

  const [ratesDosage, setRatesDosage] = useState<RatesDosageInterface>({
    rates_and_dosages: [],
    type: "",
    unit_of_rate: "",
    standard_seed_rate: 0,
    standard_product: ""
  });
  const [ratesDosageValidation, setRatesDosageValidation] =
    useState<boolean>(false);
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [trialProducts, setTrialProducts] = useState<any>(null);
  const [trialProtocolData, setTrialProtocolData] = useState<any>(null);
  const [isStandardDataValid, setIsStandardDataValid] = useState<boolean>(false);

  const memoizedValue = useMemo(
    () => ({
      newTrialState, setNewTrialState,
      trialInfo, setTrialInfo,
      ratesDosage, setRatesDosage,
      activeKeys, setActiveKeys,
      trialProducts, // todo : refactor to use trialProtocolData
      setTrialProducts,
      trialProtocolData, setTrialProtocolData,
      ratesDosageValidation, setRatesDosageValidation,
      isStandardDataValid, setIsStandardDataValid,
    }),
    [
      newTrialState, setNewTrialState,
      trialInfo, setTrialInfo,
      ratesDosage, setRatesDosage,
      activeKeys, setActiveKeys,
      trialProducts, setTrialProducts,
      trialProtocolData, setTrialProtocolData,
      ratesDosageValidation, setRatesDosageValidation,
      isStandardDataValid, setIsStandardDataValid
    ]
  );

  return (
    <NewTrialStateContext.Provider value={memoizedValue}>
      {children}
    </NewTrialStateContext.Provider>
  );
};

export const useNewTrialState = (): NewTrialStateContextValue => {
  const contextValue = useContext(NewTrialStateContext);
  if (!contextValue) {
    throw new Error(
      "useNewTrialState must be used within a NewTrialStateProvider"
    );
  }
  return contextValue;
};
