import { useEffect, useState, type ReactElement } from "react";
import { Card } from "antd";
import "../style.less";
import { useCreateProtocolForm } from "..";
import seedsIcon from "../../../../src/assets/images/planting-blue.svg";
import seedsIconDisabled from "../../../../src/assets/images/planting-gray.svg";
import fertilizerIcon from "../../../../src/assets/images/fertilizer-blue.svg";
import fertilizerIconDisabled from "../../../../src/assets/images/fertilizer-gray.svg";
import biologicalsIconDisabled from "../../../../src/assets/images/biologicals.svg";
import LabelTag from "../../../shared/components/LabelTag";
import { useSearchParams } from "react-router-dom";
import AxiosInstance from "../../../shared/utils/axios";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../shared/utils/translationUtils";

type trialTypes = string | null;

interface TrialTypeCardTypes {
  disabled?: boolean;
  selected?: boolean;
  icon?: any;
  iconDisabled?: any;
  heading: any;
  description: string;
  trialType: trialTypes;
  t?: any;
}

const TrialTypesComponent = (): ReactElement => {
  const { t } = useTranslation();
  const { form, formValidationData, setFormValidationData } =
    useCreateProtocolForm();
  const trialTypesData = [
    {
      disabled: false,
      icon: seedsIcon,
      iconDisabled: seedsIconDisabled,
      heading: "Seed",
      description: "Test new seed varieties or improve planting strategies",
      trialType: "seed",
    },
    {
      disabled: false,
      icon: fertilizerIcon,
      iconDisabled: fertilizerIconDisabled,
      heading: "Nutrient",
      description: "Optimize fertilization methods for better crop growth",
      trialType: "nutrient",
    },
    {
      disabled: true,
      iconDisabled: biologicalsIconDisabled,
      heading: "Biologicals",
      description: "Evaluate biological treatments to enhance plant health",
      trialType: "crop-protection", // TODO: name to updated everywhere as per figma
    },
  ];

  const [searchParams] = useSearchParams();
  const protocolId = searchParams.get("protocol_id");
  const [existingData, setExistingData] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<trialTypes[]>([]);

  const getProtocolData = async (): Promise<any> => {
    try {
      const response = await AxiosInstance.get(
        `/protocol/${protocolId as string}`
      );
      setExistingData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (protocolId) {
      getProtocolData().catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  }, [protocolId]);

  useEffect(() => {
    if (existingData) {
      const types =
        typeof existingData?.protocol_type === "string"
          ? existingData?.protocol_type.split(",")
          : Array.isArray(existingData?.protocol_type)
          ? existingData?.protocol_type
          : [];

      setSelectedType(types);
      form.setFieldsValue({
        protocolType: types,
      });
      setFormValidationData({ ...formValidationData, protocolType: types });
    }
  }, [existingData]);

  const handleSelection = (selectedType: trialTypes): void => {
    setSelectedType((prevSelected) => {
      const isSelected = prevSelected.includes(selectedType);
      const newSelected = isSelected
        ? prevSelected.filter((type) => type !== selectedType)
        : [...prevSelected, selectedType];
      form.setFieldsValue({ protocolType: newSelected });
      setFormValidationData({
        ...formValidationData,
        protocolType: newSelected,
      });
      return newSelected;
    });
  };

  return (
    <div className="trialTypes-component">
      <div className="trial-types-header">
        <h2>{formatTranslation(t("createProtocol.trialType.title"))}</h2>
        <p>
          {formatTranslation(t("createProtocol.trialType.description"))
            .split("\n")
            .map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
        </p>
      </div>
      <div className="trialTypes-cards-container">
        {trialTypesData.map((cardData: TrialTypeCardTypes, index: number) => (
          <div
            className="trialTypes-card-content"
            key={index}
            onClick={() => {
              handleSelection(cardData.trialType);
            }}
            onKeyDown={(e) => {
              e.key === "Enter" && handleSelection(cardData.trialType);
            }}
            role="button"
          >
            <TrialTypeCard
              disabled={cardData.disabled}
              icon={cardData.icon}
              iconDisabled={cardData.iconDisabled}
              description={cardData.description}
              heading={cardData.heading}
              selected={selectedType.includes(cardData.trialType)}
              trialType={cardData.trialType}
              t={t}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const TrialTypeCard = ({
  disabled,
  icon,
  iconDisabled,
  heading,
  description,
  selected,
  trialType,
  t,
}: TrialTypeCardTypes): ReactElement => {
  return (
    <Card
      hoverable={!disabled}
      className={`${
        !disabled && selected ? "active trialTypes-card" : "trialTypes-card"
      }`}
    >
      {disabled && <div className="backdrop"></div>}
      {disabled && (
        <LabelTag
          text={formatTranslation(t("trialtypes.label.comingSoon"))}
          type="disabled"
        />
      )}
      {/* <LabelTag type="Draft" className="disabled-label"  /> */}

      {!disabled && selected ? (
        <img className="trial-type-img" src={icon} alt="trial-type-icon"></img>
      ) : (
        <img
          className="trial-type-img"
          src={iconDisabled}
          alt="trial-type-disabled-icon"
        ></img>
      )}

      {/* <SeedIcon selected = {selected} disabled={disabled} ></SeedIcon> */}

      <h2 className="trailTypes-card-heading">{heading}</h2>
      <p className="trailtype-card-description">{description}</p>
    </Card>
  );
};

export default TrialTypesComponent;
