import { type ReactElement } from "react";
import { Card, Col, Flex, Row, Divider } from "antd";
import useAxios from "../../../shared/custom-hooks/useAxios";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { EditButton } from "../../../shared/layout/EditButton";
import { route } from "../../constant";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../shared/utils/translationUtils";

const SummaryComponent = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const protocolId: string | null = searchParams.get("protocol_id");
  let data: any = null;

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const { response, error } = useAxios(`/protocol/${protocolId}`, false);

  data = response;
  if (error) {
    console.error(error);
  }

  return (
    <div className="summary-component">
      {data && protocolId ? (
        <>
          <Card
            title={
              <div className="text-xl semibold">
                {formatTranslation(t("createProtocol.summary.title1"))}
              </div>
            }
            extra={
              <EditButton
                onClick={() => {
                  navigate({
                    pathname: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_TRIALTYPE.path}`,
                    search: createSearchParams({
                      protocol_id: protocolId,
                    }).toString(),
                  });
                }}
              />
            }
            className="summary-card"
          >
            <p className="summary-card-static-text">
              {formatTranslation(t("createProtocol.summary.type"))}
            </p>
            <p className="summary-card-dynamic-text">
              {data.protocol_type.map(
                (type: string, index: number, arr: any[]) =>
                  index === arr.length - 1 ? type : type + ", "
              )}
            </p>
          </Card>
          <Card
            title={
              <div className="text-xl semibold">
                {formatTranslation(t("createProtocol.summary.title2"))}
              </div>
            }
            extra={
              <EditButton
                onClick={() => {
                  navigate({
                    pathname: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_BASICINFO.path}`,
                    search: createSearchParams({
                      protocol_id: protocolId,
                    }).toString(),
                  });
                }}
              />
            }
            className="summary-card"
          >
            <Flex gap={16} align="start" justify="space-between">
              <div>
                <p className="summary-card-static-text">
                  {formatTranslation(
                    t("createProtocol.basicInfo.protocolName")
                  )}
                </p>
                <p className="summary-card-dynamic-text">{data.name}</p>
              </div>
              <div>
                <p className="summary-card-static-text">
                  {formatTranslation(
                    t("createProtocol.basicInfo.protocolDescription")
                  )}
                </p>
                <p className="summary-card-dynamic-text">{data.description}</p>
              </div>

              <div>
                <p className="summary-card-static-text">
                  {formatTranslation(
                    t("createProtocol.basicInfo.protocolCrop")
                  )}
                </p>
                <p className="summary-card-dynamic-text">{data.crop}</p>
              </div>

              <div>
                <p className="summary-card-static-text">
                  {formatTranslation(
                    t("createProtocol.basicInfo.plotMinimumSize")
                  )}
                </p>
                <p className="summary-card-dynamic-text">
                  {data.plot_minimum_size.width} x{" "}
                  {data.plot_minimum_size.length} m
                </p>
              </div>

              <div>
                <p className="summary-card-static-text">
                  {formatTranslation(
                    t("createProtocol.basicInfo.plotRecommendedSize")
                  )}
                </p>
                <p className="summary-card-dynamic-text">
                  {data.plot_recommended_size.width} x{" "}
                  {data.plot_recommended_size.length} m
                </p>
              </div>
            </Flex>
          </Card>
          <Card
            title={
              <div className="text-xl semibold">
                {formatTranslation(t("createProtocol.summary.title3"))}
              </div>
            }
            extra={
              <EditButton
                onClick={() => {
                  navigate({
                    pathname: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_OBJECTIVES.path}`,
                    search: createSearchParams({
                      protocol_id: protocolId,
                    }).toString(),
                  });
                }}
              />
            }
            className="summary-card"
          >
            {data?.objectives.map((objective: any, index: number) => {
              return (
                <div className="mb-8" key={index}>
                  <Row>
                    <Col span={8}>
                      <p className="summary-card-static-text">
                        {formatTranslation(
                          t("createProtocol.objectives.objectiveName")
                        )}
                      </p>
                    </Col>
                    <Col span={8}>
                      <p className="summary-card-static-text">
                        {formatTranslation(
                          t("createProtocol.objectives.objectiveItemTarget")
                        )}
                      </p>
                    </Col>
                    <Col span={8}>
                      <p className="summary-card-static-text">
                        {formatTranslation(
                          t(
                            "createProtocol.objectives.objectiveItemDescription"
                          )
                        )}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={8}>
                      <p className="summary-card-dynamic-text">
                        {objective.name}
                      </p>
                    </Col>

                    <Col span={8}>
                      <p className="summary-card-dynamic-text">
                        {objective.targets.map(
                          (target: string, index: number) =>
                            index === objective.targets.length - 1
                              ? target
                              : target + ", "
                        )}
                      </p>
                    </Col>
                    <Col span={8}>
                      <p className="summary-card-dynamic-text">
                        {objective.description}
                      </p>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </Card>
          {data.protocol_type.includes("seed") && (
            <Card
              title={
                <div className="text-xl semibold">
                  {formatTranslation(t("createProtocol.summary.title4"))}
                </div>
              }
              extra={
                <EditButton
                  onClick={() => {
                    navigate({
                      pathname: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_PRODUCTS.path}`,
                      search: createSearchParams({
                        protocol_id: protocolId,
                      }).toString(),
                    });
                  }}
                />
              }
              className="summary-card"
            >
              <Row className="products-header">
                <Col span={12}>
                  <p className="summary-card-static-text-seeds">
                    {formatTranslation(t("createProtocol.products.rightTilte"))}
                  </p>
                </Col>
                <Col span={12}>
                  <p className="summary-card-static-text-seeds">
                    {formatTranslation(
                      t("createProtocol.products.densityRange")
                    )}
                  </p>
                </Col>
              </Row>
              {data?.product_details ? (
                <>
                  {data?.product_details.products.map(
                    (product: any, index: number) => {
                      return (
                        <Row key={index} className="products-row">
                          <Col span={12}>
                            <p className="summary-card-dynamic-text">
                              {product.variety}
                            </p>
                          </Col>
                          <Col span={12}>
                            {data.product_details.global_density_range ? (
                              <p className="summary-card-dynamic-text">
                                {data.product_details.density_range.min} -{" "}
                                {data.product_details.density_range.max}{" "}
                                {data.product_details.measure_unit}
                              </p>
                            ) : (
                              <p className="summary-card-dynamic-text">
                                {product.density_range.min} -{" "}
                                {product.density_range.max}{" "}
                                {data.product_details.measure_unit}
                              </p>
                            )}
                          </Col>
                        </Row>
                      );
                    }
                  )}
                </>
              ) : (
                <>
                  {" "}
                  {formatTranslation(
                    t("createProtocol.summary.productDataNotFound")
                  )}
                </>
              )}
            </Card>
          )}
          {data.protocol_type.includes("nutrient") && (
            <Card
              title={
                <div className="text-xl semibold">
                  {formatTranslation(
                    t("createProtocol.products.nutrients.header")
                  )}
                </div>
              }
              extra={
                <EditButton
                  onClick={() => {
                    navigate({
                      pathname: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_PRODUCTS.path}`,
                      search: createSearchParams({
                        protocol_id: protocolId,
                      }).toString(),
                    });
                  }}
                />
              }
              className="summary-card"
            >
              <p className="summary-card-dynamic-text">
                P | Phosphor • (P205 | Phosphorus Pentoxide)
              </p>
              <Flex gap={16} align="start" justify="start">
                <div>
                  <p className="summary-card-static-text">
                    {formatTranslation(
                      t("createProtocol.summary.nutrients.applicationRange")
                    )}
                  </p>
                  <p className="summary-card-dynamic-text">
                    100% min - 200% max
                  </p>
                </div>
                <Divider type="vertical" style={{ height: "52px" }} />
                <div>
                  <p className="summary-card-static-text">
                    {formatTranslation(
                      t("createProtocol.summary.nutrients.minDistanceUnit")
                    )}
                  </p>
                  <p className="summary-card-dynamic-text">50%</p>
                </div>
                <Divider type="vertical" style={{ height: "52px" }} />
                <div>
                  <p className="summary-card-static-text">
                    {formatTranslation(
                      t("createProtocol.summary.nutrients.noOfIntervals")
                    )}
                  </p>
                  <p className="summary-card-dynamic-text">4</p>
                </div>
              </Flex>
            </Card>
          )}
        </>
      ) : (
        <>
          {formatTranslation(t("createProtocol.summary.errorFetchingSummary"))}
        </>
      )}
    </div>
  );
};
export default SummaryComponent;
