import { type ReactElement } from "react";
import { Outlet, useRoutes } from "react-router-dom";
import HallOfOrganisations from "./hall-of-organisations";
import HallOfFarmes from "./hall-of-farms";
import Trials from "./new-trail";
import { NewTrialStateProvider } from "./new-trail/hooks/NewTrialStateProvider";
import CreateNewTrial from "./trials";
import CreateProtocol from "./create-protocol";
import TrialTypesComponent from "./create-protocol/step-components/TrialTypesComponent";
import Protocols from "./protocols";
import BasicInfoComponent from "./create-protocol/step-components/BasicInfoComponent";
import ObjectivesComponent from "./create-protocol/step-components/ObjectivesComponent";
import ProductsComponent from "./create-protocol/step-components/ProductsComponent";
import SummaryComponent from "./create-protocol/step-components/SummaryComponent";
import { route } from "./constant";

export const AppRoutes = (): ReactElement | null => {
  return useRoutes([
    {
      path: "/app",
      element: <Outlet />,
      children: [
        {
          path: route.ORGS.path,
          element: <HallOfOrganisations />,
        },
        {
          path: route.FARMS.path,
          element: <HallOfFarmes />,
        },
        {
          path: route.TRIALS.path,
          element: <CreateNewTrial />,
        },
        {
          path: `${route.TRIALS.path}/:id`,
          element: (
            <NewTrialStateProvider>
              <Trials />
            </NewTrialStateProvider>
          ),
        },
        {
          path: route.NEWTRIAL.path,
          element: (
            <NewTrialStateProvider>
              <Trials />
            </NewTrialStateProvider>
          ),
        },
        {
          path: route.PROTOCOLS_DASHBOARD.path,
          element: <Protocols />,
        },
        {
          path: route.PROTOCOLS.path,
          element: <CreateProtocol />,
          children: [
            {
              path: route.PROTOCOLS_TRIALTYPE.path,
              element: <TrialTypesComponent />,
            },
            {
              path: route.PROTOCOLS_BASICINFO.path,
              element: <BasicInfoComponent />,
            },
            {
              path: route.PROTOCOLS_OBJECTIVES.path,
              element: <ObjectivesComponent />,
            },
            {
              path: route.PROTOCOLS_PRODUCTS.path,
              element: <ProductsComponent />,
            },
            {
              path: route.PROTOCOLS_SUMMARY.path,
              element: <SummaryComponent />,
            },
          ],
        },
      ],
    },
  ]);
};
