import { get } from "lodash";

export const populateRatesDosage = (
  trialPlotData: any,
  activeKey: string[],
  existingRates: any,
  unit?: string,
  standardSeedRate?: number,
  standardProduct?: string
): any => {
  const ratesAndDosages: any = [];
  const existingRatesDosage = existingRates?.rates_and_dosages || [];
  // const selectedUnit = get(
  //   trialPlotData,
  //   "[0].properties.seeds.measure_unit",
  //   unit
  // );
  trialPlotData?.forEach((trialPlot: any, index: number) => {
    const ratesForPlotsAndReplicants: any = [];
    const { id, rows, columns } = trialPlot.plot;
    const existingRD = existingRates?.length
      ? existingRates[index]
      : get(trialPlot, "properties.seeds.rates_and_dosages", []);
    if (existingRD?.length) {
      const allPlotIds: string[] = [];
      // Set the plot_id as it is not storing in DB
      for (let row = 0; row < rows; row++) {
        for (let col = 0; col < columns; col++) {
          const plotId = `${id as number}-${row}-${col}`;
          allPlotIds.push(plotId);
        }
      }
      ratesAndDosages?.push(
        existingRD?.map((e: any, i: number) => {
          return { ...e, plot_id: allPlotIds[i], collapseValue: activeKey };
        })
      );
    } else {
      for (let row = 0; row < rows; row++) {
        for (let col = 0; col < columns; col++) {
          const plotId = `${id as number}-${row}-${col}`;
          const existingData = existingRatesDosage[index]?.find(
            (item: any) => item.plot_id === plotId
          );
          ratesForPlotsAndReplicants.push({
            plot_id: plotId,
            rate: existingData?.rate ?? 0,
            variety: existingData?.variety ?? "",
            collapseValue: activeKey,
          });
        }
      }
      ratesAndDosages.push(ratesForPlotsAndReplicants);
    }
  });

  return {
    rates_and_dosages: ratesAndDosages,
    type: "seeds",
    unit_of_rate: unit ?? "seeds/ha", // TODO: Check if this is correct
    standard_seed_rate: standardSeedRate,
    standard_product: standardProduct,
  };
};

export default populateRatesDosage;
