import { type FC } from "react";
import { Table } from "antd";
import "./style.less";
import { type tableParams } from "../types";

export const PTable: FC<tableParams> = (params) => {
  const { loading, pagination, onChange, columns, dataSource, rowSelection } =
    params;
  return (
    <Table
      rowSelection={rowSelection}
      pagination={pagination}
      onChange={onChange}
      columns={columns}
      dataSource={dataSource}
      className="custom-table"
      loading={loading}
    />
  );
};
