import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Select, InputNumber } from "antd";
import type { ModalProps, monitorModaldata } from "../types";
import {
  MonitorBrands,
  MonitorModels,
  type MonitorBrand,
} from "./Monitor.types";
import AxiosInstance from "../../utils/axios";
import { useAppState } from "../../../lib/appContext/AppContext";
import downloadNavy from "../../../assets/images/Download - navy.svg";
import vector1 from "../../../assets/images/Vector 44325723.svg";
import vector2 from "../../../assets/images/Vector 44325724.svg";
import vector3 from "../../../assets/images/Vector 44325725.svg";
import downloadAltIcon from "../../../assets/images/download-alt.svg";
import "./TrailModal.less";
import useAxios from "../../custom-hooks/useAxios";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationUtils";

const TrailModal: React.FC<ModalProps> = ({
  open,
  handleOk,
  handleCancel,
  fieldId,
  trialId,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { Option } = Select;
  const [selectedmonitorBrand, setSelectedmonitorBrand] = useState("");
  const [selectedmonitorModel, setSelectedmonitorModel] = useState("");
  const [selectedmonitorId, setSelectedmonitorId] = useState("");
  const [geoJSONData, setGeoJSONData] = useState<any>(null);
  const [isIntraRawSpacing, setIsIntraRawSpacing] = useState(false);
  const { stateValue } = useAppState();
  const orgName = stateValue?.orgValue?.name ?? "";
  const farmName = stateValue?.farmValue?.name ?? "";
  const farmId = stateValue?.farmValue?.id ?? "";

  const { response: farmProperties } = useAxios(
    `/properties/${farmId}/fields?attributes=geometry`,
    true
  );

  const fieldData = Array.isArray(farmProperties?.content)
    ? farmProperties?.content
    : [];

  const getFieldById =
    fieldData?.length > 0 &&
    fieldData?.find((item: any) => item?.id?.includes(fieldId));

  const handleBrands = (value: string): void => {
    setSelectedmonitorBrand(value);
  };

  const filtermonitorModels = MonitorModels.filter(
    (models) =>
      models?.format?.toLocaleLowerCase() ===
      selectedmonitorBrand?.toLocaleLowerCase()
  );

  const fetchGeoJSON = async (
    moniterId?: string,
    intraRawSpacing?: number | null
  ): Promise<any> => {
    const payload = {
      field_name: getFieldById?.name,
      organization_name: orgName,
      property_name: farmName,
      field_geometry: getFieldById?.geometry,
      monitor_brand: selectedmonitorBrand,
      monitor_model: moniterId !== "" ? moniterId : selectedmonitorId,
      spacing: intraRawSpacing ?? null,
    };

    try {
      const apiResponse = await AxiosInstance.post(
        `work_order/geojson?trial_id=${(trialId as string) ?? ""}`,
        payload
      );
      setGeoJSONData(apiResponse?.data);
    } catch (error) {
      console.error("Error While Fetching Data", error);
    }
  };

  const handleModel = (value: string): void => {
    setSelectedmonitorId(value?.toLocaleLowerCase());
    const moniter = filtermonitorModels.find((m) => m?.id === value);
    moniter && setSelectedmonitorModel(moniter?.monitor?.toLocaleLowerCase());
    const isIntraUnits =
      moniter && (moniter?.units === "seed/m" || moniter?.units === "seed/10m");
    isIntraUnits
      ? setIsIntraRawSpacing(isIntraUnits)
      : fetchGeoJSON(value?.toLocaleLowerCase()).catch((err) => {
          console.error(err);
        });
  };

  const handleIntraSpacing = (value: number | null): void => {
    value &&
      fetchGeoJSON("", value).catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    setSelectedmonitorId("");
    setSelectedmonitorModel("");
    setIsIntraRawSpacing(false);
    form.resetFields(["MonitorModel", "IntraRawSpacing"]);
  }, [selectedmonitorBrand]);

  useEffect(() => {
    setGeoJSONData(null);
    form.resetFields(["IntraRawSpacing"]);
  }, [selectedmonitorId]);

  const isButtonDisabled = !selectedmonitorBrand || !selectedmonitorModel;

  const handleSubmitdmonitorModal = (e: React.MouseEvent): void => {
    const data: monitorModaldata = {
      geoJSONData,
      selectedmonitorBrand,
      selectedmonitorModel,
    };
    if (handleOk) {
      handleOk(data, getFieldById);
    } else {
      console.error("handleok is undefined");
    }
  };

  return (
    <Modal
      className="monitor-modal"
      open={open}
      title={formatTranslation(t("shared.downloadWorkOrder"))}
      onCancel={handleCancel}
    >
      <div className="ant-modal-body-left">
        <img src={downloadNavy} alt="download-img" className="download-img" />
        <img src={vector1} alt="vector1" className="vector1" />
        <img src={vector2} alt="vector2" className="vector2" />
        <img src={vector3} alt="vector3" className="vector3" />
      </div>
      <div className="ant-modal-body-right">
        <Form form={form} name="download-form" layout="vertical">
          <Form.Item
            label="Monitor Brand"
            name="MonitorBrand"
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select"
              allowClear
              onChange={handleBrands}
              value={selectedmonitorBrand}
            >
              {MonitorBrands.map((monitor: MonitorBrand) => {
                return (
                  <Option value={monitor?.format} key={monitor?.label}>
                    {monitor?.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label={formatTranslation(t("shared.monitorModel"))}
            name="MonitorModel"
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select"
              onChange={handleModel}
              value={selectedmonitorId}
              allowClear
            >
              {filtermonitorModels.map((model) => {
                return (
                  <Option value={model?.id} key={model?.id}>
                    {model?.monitor}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          {isIntraRawSpacing && (
            <Form.Item
              className="intra-raw-field"
              label={formatTranslation(t("shared.intraRawSpacing"))}
              name="IntraRawSpacing"
              rules={[{ required: true }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={1}
                placeholder="Enter Intra Raw Spacing"
                onChange={handleIntraSpacing}
              />
            </Form.Item>
          )}

          <div className="modal-button">
            <Button className="cancel-btn" size="large" onClick={handleCancel}>
              {formatTranslation(t("createProtocol.objectives.cancelButton"))}
            </Button>
            <Button
              className={
                isButtonDisabled || !geoJSONData
                  ? "download-btn-disabled"
                  : "download-btn"
              }
              size="large"
              onClick={handleSubmitdmonitorModal}
              disabled={!geoJSONData || isButtonDisabled}
            >
              {formatTranslation(t("shared.downloadWorkOrder"))}
              <img src={downloadAltIcon} alt="download -alt-icon" />
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default TrailModal;
