import { route } from "../constant";
import { type objectivesTargetType, type ProtocolStepItemType } from "./types";
import { type TabsProps } from "antd";
import ProductsSeedComponent from "./step-components/Products/ProductsSeedComponent";
import ProductsNutrientsComponent from "./step-components/Products/ProductsNutrientsComponent";

export const protocolsSteps: ProtocolStepItemType[] = [
  {
    title: "Trial Type",
    path: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_TRIALTYPE.path}`,
    stepNumber: 1,
  },
  {
    title: "Basic Information",
    path: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_BASICINFO.path}`,
    stepNumber: 2,
  },
  {
    title: "Objectives",
    path: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_OBJECTIVES.path}`,
    stepNumber: 3,
  },
  {
    title: "Products",
    path: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_PRODUCTS.path}`,
    stepNumber: 4,
  },
  {
    title: "Summary",
    path: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_SUMMARY.path}`,
    stepNumber: 5,
  },
];

export const SUBMIT_PROTOCOL_LABEL = "Save and configure trial";
export const targets: objectivesTargetType[] = [
  {
    label: "Yield",
    value: "Yield",
  },
  {
    label: "Moisture",
    value: "Moisture",
  },
  {
    label: "Peak NDVI",
    value: "Peak NDVI",
  },
  {
    label: "Dry Matter",
    value: "Dry Matter",
  },
  // {
  //   label: "Seed Rate",
  //   value: "seed_rate",
  // },
  // {
  //   label: "Sunflower Rate",
  //   value: "sunflower_rate",
  // },
  // {
  //   label: "Silage Rate",
  //   value: "silage rate",
  // },
  // {
  //   label: "Cereals Rate",
  //   value: "cereals rate",
  // },
  // {
  //   label: "Fert Rate",
  //   value: "fert rate",
  // },
];

export const dummyObjectives = [
  {
    name: "First Objective",
    target: "Crop Establishment",
    description: "This is a test description for the first objective",
  },
  {
    name: " Objective 2",
    target: "Crop Establishment",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
  },
];

export const cropOptionsObject = [
  {
    value: "corn",
    label: "Corn",
    emoji: "img",
    desc: "(corn)",
  },
  {
    value: "sunflower",
    label: "Sunflower",
    emoji: "img",
    desc: "(sunflower)",
  },
];

export const measureUnitOptions = [
  {
    value: "seeds/m2",
    label: (
      <>
        seeds/m<sup>2</sup>
      </>
    ),
  },
  {
    value: "seeds/ha",
    label: <>seeds/ha</>,
  },
];

export const nutrientTypeOptions: any = [
  {
    label: <span className="nutrient-type-category">Basic Nutrients</span>,
    title: "Basic Nutrients",
    options: [
      {
        label: <span>N | Nitrogen</span>,
        value: "N | Nitrogen",
        key: "nitrogen",
      },
      {
        label: <span>P | Phosphorus (P2O5 | Phosphorus Pentoxide)</span>,
        value: "P | Phosphorus (P2O5 | Phosphorus Pentoxide)",
        key: "phosphorusPentoxide",
      },
      {
        label: <span>K | Potassium (K2O | Potassium Oxide)</span>,
        value: "K | Potassium (K2O | Potassium Oxide)",
        key: "potassiumOxide",
      },
    ],
  },
  {
    label: <span className="nutrient-type-category">Aditional Nutrients</span>,
    title: "Aditional Nutrients",
    options: [
      {
        label: <span>Cd | Cadmium</span>,
        value: "Cd | Cadmium",
        key: "cadmium",
      },
      { label: <span>Pb | Lead</span>, value: "Pb | Lead", key: "lead" },
      {
        label: <span>Cr | Chromium</span>,
        value: "Cr | Chromium",
        key: "chromium",
      },
      {
        label: <span>Hg | Mercury</span>,
        value: "Hg | Mercury",
        key: "mercury",
      },
    ],
  },
  {
    label: <span className="nutrient-type-category">Other Nutrients</span>,
    title: "Aditional Nutrients",
    options: [
      {
        label: <span>Mg | Magnesium</span>,
        value: "Mg | Magnesium",
        key: "magnesium",
      },
      { label: <span>Zn | Zinc</span>, value: "Zn | Zinc", key: "zinc" },
      { label: <span>B | Boron</span>, value: "B | Boron", key: "boron" },
      { label: <span>Fe | Iron</span>, value: "Fe | Iron", key: "iron" },
      {
        label: <span>Mn | Manganese</span>,
        value: "Mn | Manganese",
        key: "manganese",
      },
      { label: <span>Cu | Copper</span>, value: "Cu | Copper", key: "copper" },
      {
        label: <span>Mo | Molybdenum</span>,
        value: "Mo | Molybdenum",
        key: "molybdenum",
      },
      {
        label: <span>Se | Selenium</span>,
        value: "Se | Selenium",
        key: "selenium",
      },
      {
        label: <span>Si | Silicon</span>,
        value: "Si | Silicon",
        key: "silicon",
      },
      { label: <span>Ni | Nickel</span>, value: "Ni | Nickel", key: "nickel" },
      { label: <span>Co | Cobalt</span>, value: "Co | Cobalt", key: "cobalt" },
      {
        label: <span>Al | Aluminum</span>,
        value: "Al | Aluminum",
        key: "aluminum",
      },
      { label: <span>Na | Sodium</span>, value: "Na | Sodium", key: "sodium" },
      {
        label: <span>Ca | Calcium</span>,
        value: "Ca | Calcium",
        key: "calcium",
      },
      { label: <span>S | Sulfur</span>, value: "S | Sulfur", key: "sulfur" },
    ],
  },
];

export const protocolProductTabs = (protocolType: any): TabsProps["items"] => [
  {
    key: "0",
    label: <div className="label-style">Seeds</div>,
    children: <ProductsSeedComponent />,
    disabled: !protocolType?.includes("seed"),
  },
  {
    key: "1",
    label: <div className="label-style">Nutrients</div>,
    children: (
      <div className="products-nutrients-outer-div">
        <ProductsNutrientsComponent />
      </div>
    ),
    disabled: !protocolType?.includes("nutrient"),
  },
  // {
  //   key: "2",
  //   label: "Biologicals",
  //   children: <ProductsBiologicalsComponent />,
  // },
];
